<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.show_conclusions") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <table
            style="background: #fff"
            class="table table-bordered table-hover"
            v-loading="loadingData"
          >
            <thead>
              <tr>
                <crm-th
                  :column="columns.id"
                  :sort="sort"
                  @c-change="updateSort"
                ></crm-th>
                <crm-th
                  :column="columns.patient_id"
                  :sort="sort"
                  @c-change="updateSort"
                ></crm-th>
                <crm-th
                  :column="columns.doctor_id"
                  :sort="sort"
                  @c-change="updateSort"
                ></crm-th>
                <crm-th
                  :column="columns.service_id"
                  :sort="sort"
                  @c-change="updateSort"
                ></crm-th>
                <crm-th
                  :sort="sort"
                  :column="columns.created_at"
                  @c-change="updateSort"
                ></crm-th>
                <crm-th
                  :sort="sort"
                  :column="columns.settings"
                  @c-change="updateSort"
                ></crm-th>
              </tr>
              <tr>
                <th>
                  <el-input
                    clearable
                    size="mini"
                    class="id_input"
                    v-model="filterForm.id"
                    :placeholder="columns.id.title"
                  ></el-input>
                </th>
                <th></th>
                <th></th>
                <th></th>

                <th>
                  <el-date-picker
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                    v-model="filterForm.created_at"
                    :placeholder="columns.created_at.title"
                    size="mini"
                  >
                  </el-date-picker>
                </th>
                <th></th>
              </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
              <tr v-for="item in list" :key="item.id" class="cursor-pointer">
                <td>{{ item.id }}</td>
                <td>
                  {{
                    item.patient
                      ? item.patient.first_name + " " + item.patient.surname
                      : ""
                  }}
                </td>
                <td>
                  {{
                    item.doctor
                      ? item.doctor.name +
                        " " +
                        (item.doctor.surname ? item.doctor.surname : "")
                      : ""
                  }}
                </td>
                <td>
                  <span v-if="item.order">
                    <span
                      v-for="(service, index) in item.order.services"
                      :key="index"
                    >
                      {{ service.name }}<br>
                    </span>
                  </span>
                  <span v-else> {{ "-" }} </span>
                </td>
                <td>
                  {{ item.created_at }}
                </td>
                <td class="settings-td">
                  <el-button
                    round
                    @click="show(item)"
                    size="mini"
                    type="primary"
                    icon="el-icon-view"
                    >{{ $t("message.show_0") }}</el-button
                  >
                  <!-- <crm-setting-dropdown :model="item" name="conclusions" :actions="actions" @edit="edit" @delete="destroy">
                        </crm-setting-dropdown> -->
                </td>
              </tr>
            </transition-group>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import list from "@/utils/mixins/list";
export default {
  mixins: [form, drawerChild, list],
  props: {
    reloadModel: { type: Boolean, default: true },
    selected_patient: { type: Object },
  },
  data() {
    return {
      waiting: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
      loadingData: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      list: "conclusions/list",
      filter: "conclusions/filter",
      sort: "conclusions/sort",
      columns: "conclusions/columns",
      token: "auth/token",
      pagination: "conclusions/pagination",
    }),
  },
  methods: {
    ...mapActions({
      updateList: "conclusions/index",
      updateSort: "conclusions/updateSort",
      updateFilter: "conclusions/updateFilter",
      updateColumn: "conclusions/updateColumn",
      updatePagination: "conclusions/updatePagination",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        patient_id: this.selected_patient.id,
        written_conclusion: true,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    async show(model) {
      var name =
        model.order_id +
        " " +
        model.patient.surname +
        " " +
        model.patient.first_name;
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation?order_id=" +
          model.order_id +
          "&name=" +
          name +
          "&user=" +
          this.token +
          "&status=show" +
          "&user_type=laborant"
      );
      link.setAttribute("target", "_blank");
      link.click();

      return;
    },
    async afterOpened() {
      this.waiting = true;
      const query = { id: this.selected_id, relation: true };
      this.fetchData();
    },
    afterClosed() {},
    back(close = true) {
      this.backTo(this.model.order_id).then((res) => {
        this.$emit("c-close", { drawer: "drawerUpdate" });
        this.$refs["form"].resetFields();
      });
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
